import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FootnoteA = makeShortcode("FootnoteA");
const Footnote = makeShortcode("Footnote");
const BoxedText = makeShortcode("BoxedText");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "writing-rm-because-of-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#writing-rm-because-of-windows",
        "aria-label": "writing rm because of windows permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Writing `}<inlineCode parentName="h1">{`rm`}</inlineCode>{` because of Windows`}</h1>
    <h2 {...{
      "id": "problem",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#problem",
        "aria-label": "problem permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Problem`}</h2>
    <p>{`I happen to have a Windows laptop in my possession, and I’m stubborn enough
to use it for coding. Fortunately, I am also stubborn enough to get it to
work.`}</p>
    <h3 {...{
      "id": "rm--rf-doesnt-work-on-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#rm--rf-doesnt-work-on-windows",
        "aria-label": "rm  rf doesnt work on windows permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a><em parentName="h3">{`rm -rf`}</em>{` doesn’t work on Windows.`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Powershell on it has `}<em parentName="p"><a parentName="em" {...{
              "href": "https://docs.microsoft.com/en-us/powershell/module/microsoft.powershell.management/remove-item?view=powershell-7.2"
            }}>{`Remove-Item`}</a></em>{` aliased to `}<em parentName="p">{`rm`}</em>{`.`}</p>
        <p parentName="li">{`This would almost solve the problem, but…`}</p>
        <pre parentName="li" {...{
          "className": "night-owl-no-italics vscode-highlight",
          "data-language": ""
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}>{`Remove-Item : A parameter cannot be found that matches parameter name 'rf'.`}</span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}>{`At line:1 char:4`}</span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}>{`+ rm -rf dist`}</span></code></pre>
        <p parentName="li">{`Powershell, unlike `}<em parentName="p"><a parentName="em" {...{
              "href": "https://en.wikipedia.org/wiki/Getopt"
            }}>{`getopt`}</a></em>{`, doesn’t allow specifying multiple options
together.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`rm -r -f`}</em>{` doesn’t work too, because `}<em parentName="p">{`-f`}</em>{` is ambiguous.`}</p>
        <pre parentName="li" {...{
          "className": "night-owl-no-italics vscode-highlight",
          "data-language": ""
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}>{`Remove-Item : Parameter cannot be processed because the parameter name 'f' is ambiguous.`}</span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}>{`Possible matches include: -Filter -Force.`}</span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}>{`At line:1 char:7`}</span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}>{`+ rm -r -f dist`}</span></code></pre>
        <p parentName="li">{`All right, for a few months I’ve been writing `}<em parentName="p">{`rm -r -fo`}</em>{` whenever I had
to remove `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{`.`}</p>
        <p parentName="li">{`I aliased`}<FootnoteA number={1} mdxType="FootnoteA" />{` it to `}<em parentName="p">{`rmrf`}</em>{` for convenience and with
the following`}</p>
        <pre parentName="li" {...{
          "className": "night-owl-no-italics vscode-highlight",
          "data-language": "powershell"
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk3"
              }}>{`function`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mtk7"
              }}>{`rmrf`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`  `}</span><span parentName="span" {...{
                "className": "mtk3"
              }}>{`param`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`        [`}</span><span parentName="span" {...{
                "className": "mtk5"
              }}>{`parameter`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mtk44"
              }}>{`Position`}</span><span parentName="span" {...{
                "className": "mtk3"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mtk8"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`)][`}</span><span parentName="span" {...{
                "className": "mtk3"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "mtk7"
              }}>{`$directory`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`  )`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`  `}</span><span parentName="span" {...{
                "className": "mtk3"
              }}>{`process`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`     `}</span><span parentName="span" {...{
                "className": "mtk6"
              }}>{`# rm -r -fo $directory`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`     `}</span><span parentName="span" {...{
                "className": "mtk13"
              }}>{`Remove-Item`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mtk3"
              }}>{`-`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`Recurse `}</span><span parentName="span" {...{
                "className": "mtk3"
              }}>{`-`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`Force `}</span><span parentName="span" {...{
                "className": "mtk7"
              }}>{`$directory`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`}`}</span></span></code></pre>
      </li>
    </ol>
    <Footnote number={1} mdxType="Footnote">
      <p>{`Powershell aliases can’t be used to partially apply arguments. Docs for
`}<em parentName="p"><a parentName="em" {...{
            "href": "https://docs.microsoft.com/en-us/powershell/module/microsoft.powershell.utility/set-alias?view=powershell-7#example-5-create-an-alias-for-a-command-with-parameters"
          }}>{`Set-Alias`}</a></em>{` basically tell you to write a function instead.`}</p>
    </Footnote>
    <h3 {...{
      "id": "alias-was-not-enough",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alias-was-not-enough",
        "aria-label": "alias was not enough permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Alias was not enough`}</h3>
    <p>{`When I started my career in Polish companies, we often had somebody on
MacOS, somebody on Linux and somebody on Windows in the team. Mac was
slightly more popular as a standard `}<em parentName="p">{`company issued notebook for
developers`}</em>{`, but I remember we often had a mix.`}</p>
    <p>{`When I work on open source, the projects are usually system agnostic (it’s
not super hard in web ecosystem), using `}<inlineCode parentName="p">{`rimraf`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`rm -rf`}</inlineCode>{`, using
`}<inlineCode parentName="p">{`dotenv`}</inlineCode>{`, writing scripts in JavaScript or Python etc.`}</p>
    <p>{`Surprisingly, 41.2% StackOverflow Survey respondents use Windows and only
30% use MacOS.`}</p>
    <p><a parentName="p" {...{
        "href": "https://insights.stackoverflow.com/survey/2021#most-popular-technologies-op-sys-prof"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "883px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABiElEQVQoz22Sy07CQBSGG+MCKi1tpzfa0mItUDrTaUsvUDABvLFio2HlyoVP4Iq3cKFrE2Ji4tKNiU/nIKQBJPkzyUnON/9/Zg7FqaFu55qdy1r4MoE/8+TrNv6eZ+9TpCuQBj4r+syWWAnXTvuKlQGtS5GCVyOiqowjCw0d3HOCrBmGDZ8G6FjAJQFVBLjNEz9ODUg/RYoKQGuVxbAkx6oMO7rnGhCbMLPcphnwWsIAVMBFP1WEYUQk612jPX7Anc8L+218thzZrxN4l+Z8vc+KiNnNT7RxpgVIJCgBsAb3vvcxsZdj5/m87eIr1ujRnMusbv8HE5JTwprVU8zMdkdGazRHaJF1nqLmjRcBK2cBZA+Ra9jn1bDW6BNeNXst/3LQSZRGfsSjsoBWo+6R0q7zKjYP/+QBPdbM1HIGxLBKsF2ywiOa8U64zeNTQo18UlCIJGQPJVyRAgJWbIRD6SzdwGRD5HoqGQk5ZTOT6qloJKIeMyLe2w2a9Zqj6+niEc9mnBRwSvALuF5R3wzTP7oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "StackOverflow Developer Survey",
            "title": "StackOverflow Developer Survey",
            "src": "/static/f96ce0f11fa55211b0739e3d97faf5cd/fe9f1/2022-05-14-14-47-10.png",
            "srcSet": ["/static/f96ce0f11fa55211b0739e3d97faf5cd/e4d6b/2022-05-14-14-47-10.png 345w", "/static/f96ce0f11fa55211b0739e3d97faf5cd/1e043/2022-05-14-14-47-10.png 690w", "/static/f96ce0f11fa55211b0739e3d97faf5cd/fe9f1/2022-05-14-14-47-10.png 883w"],
            "sizes": "(max-width: 883px) 100vw, 883px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <p>{`My anecdata looks more like 75% MacOS, 15% Linux, 10% Windows, but from a
perspective of open source guy, I wouldn’t want to break the build for 10%
of users or lose 10% of contributors.`}</p>
    <p>{`However, in a startup setting, the situation is totally different.`}</p>
    <p>{`The company offered to buy me a Mac, but the orders take about 7 weeks. So,
I had a choice. I could either bother my teammates to make the project
system-agnostic, or deal with it on my side.`}</p>
    <p>{`A smarter man might have gone to the store, get any MacBook they have, and
be done with it.`}</p>
    <h2 {...{
      "id": "writing-my-own-rm-in-rust",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#writing-my-own-rm-in-rust",
        "aria-label": "writing my own rm in rust permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Writing my own `}<inlineCode parentName="h2">{`rm`}</inlineCode>{` in Rust`}</h2>
    <p>{`Googling “rm for Windows” didn’t get me any useful results, so I quickly
wrote my own, very dirty, totally not production-grade, `}<inlineCode parentName="p">{`rm`}</inlineCode>{` to stick in my
`}<inlineCode parentName="p">{`bin`}</inlineCode>{` directory.`}</p>
    <BoxedText mdxType="BoxedText">
      <p><strong parentName="p">{`Disclaimer:`}</strong>{` Writing the code took me less time than I’ve been writing
this note, so I provide no guarantees to the quality and correctness of the
code. I didn’t even write any error messages, because exposing the error
from `}<inlineCode parentName="p">{`std::fs`}</inlineCode>{` is totally fine for my use case.`}</p>
    </BoxedText>
    <details>
  <summary><code>main.rs</code></summary>
      <pre {...{
        "className": "night-owl-no-italics vscode-highlight",
        "data-language": "rust"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`use`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` clap`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`Parser;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`#[derive(Parser, Debug)]`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`#[clap(author, version, about, long_about = None)]`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`struct`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` Args {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  #[clap(short, long)]`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  recursive: `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`bool`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  #[clap(short, long)]`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  force: `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`bool`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  #[clap()]`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  files: `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`Vec<String>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`fn`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`rm`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(args: Args) -> std::io::`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`Result`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`<()> {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`for`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` file `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`in`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` args.files {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` args.recursive {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` args.force {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`              std`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`fs`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`remove_dir_all`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(file)?;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          } `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`else`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`              std`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`fs`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`remove_dir`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(file)?;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          }`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      } `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`else`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`          std`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`fs`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`remove_file`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(file)?;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`      }`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` std`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`io`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`Result`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::Ok`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(());`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`fn`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`main`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`let`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` args `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` Args`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`parse`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`();`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`Result`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`::`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`unwrap`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`rm`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(args))`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`}`}</span></span></code></pre>
    </details>
    <details>
      <summary><code>Cargo.toml</code></summary>
      <pre {...{
        "className": "night-owl-no-italics vscode-highlight",
        "data-language": "toml"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`[package]`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`name = "rust-rm"`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`version = "0.1.0"`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`edition = "2021"`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`[dependencies]`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`clap = { version = "3.1.18", features = ["derive"] }`}</span></code></pre>
    </details>
    <p>{`I removed the alias by adding the following to my `}<inlineCode parentName="p">{`$PROFILE`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "powershell"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk13"
          }}>{`Remove-Item`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`Path Alias:rm`}</span></span></code></pre>
    <p>{`I promptly ran `}<inlineCode parentName="p">{`cargo build --release`}</inlineCode>{` and I added my newest toy to a
directory I already had in `}<inlineCode parentName="p">{`PATH`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`❯ ls`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    Directory: D:\\tools\\bin`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Mode                 LastWriteTime         Length Name`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`----                 -------------         ------ ----`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`-a----        04/05/2022     16:24           1128 colormode.ps1`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`-a----        11/05/2022     17:00         654848 rm.exe`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`-a----        12/07/2021     16:21              8 sh.cmd`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`-a----        07/07/2021     15:01         782336 tr.exe`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`-a----        20/07/2021     13:16            637 xargs.cmd`}</span></code></pre>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      